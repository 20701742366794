import React from 'react';
import { Button } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { themeVars } from '../../../config/styles';

// MUI Overrides
const styles = {
  root: {
    borderRadius: 0,
    fontSize: 16,
    fontWeight: 600,
    padding: 0,
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
    color: themeVars.root.primary,
  },

  white: {
    borderRadius: 0,
    fontSize: 16,
    fontWeight: 600,
    color: '#FFF',
    padding: 0,
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
  },
};

const MUIButtonText = (props) => {
  const { classes } = props;

  return (
    <Button
      className={props.color === 'white' ? classes.white : classes.root}
      size="large"
      type="submit"
      variant="text"
      href={props.href}
    >
      {props.children}
    </Button>
  );
};

MUIButtonText.defaultProps = {
  color: 'default',
  href: null,
};

MUIButtonText.propTypes = {
  classes: PropTypes.shape({
    white: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  href: PropTypes.string,
};

export default withStyles(styles)(MUIButtonText);
