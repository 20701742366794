/**  */

// eslint-disable-next-line
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'gatsby';
import { css, jsx } from '@emotion/core';

// Components
import Section from '../components/Common/Section/Section';
import MUIButtonText from '../components/Common/Button/MUIButtonText';
import Layout404 from '../components/Layouts/Layout404';

const cssText = css`
  font-size: 2.2rem;
  margin: 45px 0;
`;

const cssTitle = css`
  color: #fff;
`;

const cssContent = css`
  height: calc(100vh - 163px);
  color: #fff;
`;

const NotFoundPage = () => (
  <Layout404 title="404">
    <main>
      <Section>
        <Container>
          <Row>
            <Col md={12}>
              <div css={cssContent}>
                <h1 css={cssTitle}>Page not found</h1>

                <p css={cssText}>
                  The page you requested could not be located. Please try again at a later time.
                </p>

                <Link to="/" aria-label="Home">
                  <MUIButtonText color="white">Back to home</MUIButtonText>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </main>
  </Layout404>
);

export default NotFoundPage;
